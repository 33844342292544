import resume from "../assets/pdf/resume.pdf";

export const headerData = {
  name: "Tazwar Islam",
  title: "Software Engineer",
  desciption:
    "I'm  a Software Engineer with 5+ years of experience.Let's collaborate and shape the digital landscape together!",
  // image: "https://i.ibb.co/Gk4g6f3/bhodro.png",
  image: "https://i.ibb.co.com/pJ8SYy4/IMG-6804.jpg",
  backup_image: "https://i.postimg.cc/529Rz5YS/IMG-6804.jpg",
  resumePdf: resume,
};
